
import './App.css';
import bg from './assets/img/tpad_smoke.png';

import React,{Fragment, useState, useEffect, useCallback} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Container from './containers/index';


import { createweb3Modal } from "./store/web3Modal/createweb3Modal";
import { useConnectWallet, useDisconnectWallet } from "./store/web3Modal/hooks";






function App() {

  const {connectWallet,web3,address,networkId,connected} = useConnectWallet();
  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null);
    useEffect(() => {
  setModal(createweb3Modal);
  }, [setModal]);
  
  useEffect(() => {
  if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
  connectWallet(web3Modal);
  }
  }, [web3Modal, connectWallet]);
  
  const connectWalletCallback = useCallback(() => {
  connectWallet(web3Modal);
  }, [web3Modal, connectWallet]);
  
  const disconnectWalletCallback = useCallback(() => {
  disconnectWallet(web3, web3Modal);
  }, [web3, web3Modal, disconnectWallet]);


 return (
 <>
 <BrowserRouter basename={'/'}>
 <div className="bg-no-repeat bg-cover relative" style={{backgroundImage: `url(${bg})`}}>
<Header address={address}
connected={connected}
networkId={networkId}
connectWallet={connectWalletCallback}
disconnectWallet={disconnectWalletCallback}/>
      <Switch>
      <Route path="/" component={Container} exact /> 
      <Route path="*" component={Container} /> 
      </Switch>
      <Footer/>
    </div>

    </BrowserRouter>

    </>
  );

}

export default App;
