import Web3 from 'web3';
import moment from 'moment';
import axios from 'axios';

   
   export const getList = async () => {
    try {
    let result= await axios.get("/api/project.json");
    return result.data;
    } catch (error) {
    return null;
    }
    } 



    export const getTime =() => {
    return moment.utc().format("MMM DD, HH:mm");
    }
        


export const getPercentage =(startTime,endTime) => {
var startOfDate =  moment(new Date(startTime*1000)),
endDate =  moment(new Date(endTime*1000)),
todayDate =  moment();
 const daysDifference = moment(endDate).diff(startOfDate, 'days');
 const difference = todayDate.diff(startOfDate, 'days');
 const result = Math.round((difference / daysDifference) * 100);
return result;
}


export const unixTime =(unixtime) => {
return moment.unix(unixtime).format("YYYY-MM-DD HH:mm A");

}

export const unixTimeL =(unixtime) => {
return moment(unixtime).format('L');
}


export const shortAddress = (addr, start = 6, end = 4) =>
`${addr.slice(0, start)}...${addr.slice(addr.length - end, addr.length)}`;



export const isAddress = (address) => {
    return Web3.utils.isAddress(address);
    }
    
    export const toChecksumAddress = (address) => {
    return Web3.utils.toChecksumAddress(address);
    }
    
    export const fromWeiToNumber = (weiAMount) => {
        return Web3.utils.fromWei(weiAMount, 'ether');
        }
        export const fromEthToWei = (ethAMount) => {
            return Web3.utils.toWei(ethAMount, 'ether');
            }

    export const weiToBalance = (number,decimal) => {
    return (number / 10**decimal).toFixed(2);

    }
    
    export const maxUint = () => {
        return Web3.utils.toBN(2).pow(Web3.utils.toBN(256)).sub(Web3.utils.toBN(1));
        }


export const numberWithCommas = (x) => {
return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const unixTimeToHuman = (timestamp) => {
var date = new Date(timestamp*1000);
return (date.getDate()+
"-"+(date.getMonth()+1)+
"-"+date.getFullYear()+
" "+date.getHours()+
":"+date.getMinutes()+
":"+date.getSeconds());
}
