
import WalletConnectProvider from '@walletconnect/web3-provider';
const POLLING_INTERVAL = 12000;

export const providerOptions = {
walletconnect: {
package: WalletConnectProvider, // required
options: {
rpc: {
56: 'https://bsc-dataseed.binance.org/',
//97:'https://data-seed-prebsc-1-s1.binance.org:8545/',
},
},
bridge: "https://bridge.walletconnect.org",
qrcode: true,
pollingInterval: POLLING_INTERVAL
},
};

export const airdropContract="0x493598649A1764Ecf1DCcB8144ad21Eaf203Ad01";
export const SiteName = 'TrustPad Community';



