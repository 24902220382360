import React, { useState, useEffect } from 'react';
import pancakeswap from '../assets/img/pancakeswap.svg';
import logo from '../assets/img/tpad-logo.png';
import { shortAddress, fromWeiToNumber, getTime } from '../helper/';
import token from '../utils/tokenContract';
import web3New from '../utils/web3';

import { SiteName } from '../config';

const Header = ({ address, connected, connectWallet }) => {

  const [tokenBlc, setTokenBlc] = useState(0);
  const [bnbBlc, setBnbBlc] = useState(0);

  useEffect(() => {
    if (address) {
      getTokenBalance(address);
      getBnbBalance(address)
    }
  }, [address])


  const getTokenBalance = async (address) => {
    try {
      const result = await token.methods.balanceOf(address).call()
      
    } catch (error) {
      console.log(error, 'getTokenBalance');
    }
  }



  const getBnbBalance = async (address) => {
    try {
      const result = await web3New.eth.getBalance(address);
      setBnbBlc(fromWeiToNumber(result));
    } catch (error) {
      console.log(error, 'getBnbBalance');
    }
  }









  return (
    <header>
      <nav className="navbar flex flex-col lg:flex-row container">
        <a className="text-white no-underline pr-4 flex items-center no-hover mb-3 lg:mb-0" href="/">
          <div>
            <img src={logo} alt="Logo" className="mr-2 -mb-1 h-14" />
          </div>
          <div><div className="text-xl font-secondary">PUMP YOUR WALLET</div>
            <div className="text-xs tracking-wide">The #1 Pump Team.</div>
          </div>
        </a>
        <div className="flex flex-col lg:flex-row justify-between items-center lg:ml-auto">
          <div className="lg:mr-3 py-3 lg:py-0 flex items-center flex-wrap">
            <a href="#pools" className="nav-link text-white">Pools 🔥</a>
            <a className="nav-link text-white" href="/levels">Levels</a>
            <a className="nav-link text-white" href="/levels#faq">FAQ</a>
            

            
          </div>

          <div className="relative py-3">

            {connected ? (

              <div className="text-white border-2 border-main py-0 px-3 rounded-lg flex items-center">
                <div className="mr-3 text-sm font-medium flex flex-col pt-1 leading-tight">
                  <span>{bnbBlc} BNB</span>
                 
                </div>
                <button type="button" className="p-0 m-0 mr-3 flex items-center outline-none btn btn-dark-outline">
                  <span className="UserWallet_addressPill__FOL8j badge badge-pill badge-secondary">
                    <span className>{shortAddress(address)}</span>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="inline align-bottom text-sm ml-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z"></path></svg>
                  </span>
                </button>


                <div className="dropdown">
                  <a href className="outline-none">
                    <div className style={{ height: '24px', width: '24px', borderRadius: '12px' }}>
                      <div style={{ borderRadius: '50px', overflow: 'hidden', padding: '0px', margin: '0px', width: '24px', height: '24px', display: 'inline-block', background: 'rgb(3, 86, 94)' }}>
                        <svg x={0} y={0} width={24} height={24}><rect x={0} y={0} width={24} height={24} transform="translate(-0.37286592004401453 2.3669788661007374) rotate(196.9 12 12)" fill="#C81459" /><rect x={0} y={0} width={24} height={24} transform="translate(4.662004535494584 -6.871287823594915) rotate(422.9 12 12)" fill="#2372E1" /><rect x={0} y={0} width={24} height={24} transform="translate(-17.78090244798719 2.6024766025283568) rotate(348.1 12 12)" fill="#FB186F" /></svg>

                      </div>
                    </div>
                  </a>
                </div>
              </div>

            ) : (

              <div className="ConnectButtons_container__Cj8KL">
                <div className="dropdown">
                  <button type="button" onClick={connectWallet} className="btn btn-main">Connect Wallet</button>
                </div>
              </div>

            )
            }







            <div className="absolute right-0">
              <div className="opacity-70 text-gray-300 tracking-wide" style={{ fontSize: '0.75rem' }}>{getTime()} UTC</div>
            </div>
          </div>
        </div>
      </nav>
    </header>









  )
}


export default Header;
