import React from 'react';
import pancakeswap from '../assets/img/pancakeswap.svg';
import logo from '../assets/img/tpad-logo.png';







import { useLocation, useHistory, Link } from 'react-router-dom'

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer className="bg-dark2 relative border-t border-gray-500 border-opacity-25">
      <div className="flex flex-col px-4 pt-10 mx-auto container lg:px-5 pb-14 gap-20 md:gap-12">
        <div className="flex flex-col md:flex-row justify-between gap-12 text-sm font-normal">
          <div className="flex flex-col xl:flex-row gap-6">
            <a className="text-white mr-auto no-underline pr-4 flex items-center no-hover" href="/">
              <div><img src={logo} alt="Logo" className="mr-2 -mb-1 h-14" /></div>
              <div><div className="text-xl font-secondary">PUMP YOUR WALLET</div>
                <div className="text-xs tracking-wide">FIRST DEFI PUMP GROUP</div>
              </div></a>
            <p className="text-secondary text-sm flex-1">We Offer PUMPS in POPULAR DEX Named PancakeSwap. Load BNB in Binance Smart Chain To get Started. </p></div><div>
            <ul className="flex items-center space-x-8">
              <li><a href="https://t.me/TrustPadAnn" target="_blank" className="text-main" rel="noreferrer">
                <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 576 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z" /></svg></a></li><li><a href="https://t.me/TrustPad" target="_blank" className="text-main" rel="noreferrer">
                  <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" /></svg></a></li><li>  </li>


                  <li className="mb-4 last:mb-0">
                    <a href="https://trustpad.io/staking" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"></a></li><li className="mb-4 last:mb-0"><a href="https://docs.google.com/forms/d/e/1FAIpQLSer-PUjhMDG5fmasXYkmvY3EgpTC-yaI4up5by6Hx5g_9wZaw/viewform" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"></a></li>
                  <li className="mb-4 last:mb-0"><a href="/privacy" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"></a></li><li className="mb-4 last:mb-0"><a href="/tos" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"></a></li></ul></div><div className="text-sm"><p className="mb-4 text-lg text-secondary">Help</p><ul><li className="mb-4 last:mb-0"><a href="https://docs.trustpad.io/trustpad/how-to-participate-in-an-ido" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main">How to participate in PUMPS</a></li>
                    <li className="mb-4 last:mb-0"><a href="https://trustpad.io/levels#faq" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main">FAQ</a></li><li className="mb-4 last:mb-0"><a href="https://docs.trustpad.io/trustpad/master/redistribution-fee" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main">About TPAD redistribution fee</a></li></ul></div><div className="text-sm"><p className="mb-4 text-lg text-secondary">Communities</p><ul><li className="mb-4 last:mb-0"><a href="https://t.me/TrustPad" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main">English</a></li><li className="mb-4 last:mb-0"><a href="https://discord.gg/4AG5B8Y8gW" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main">  </a></li><li className="mb-4 last:mb-0"><a href="https://t.me/TrustNFTPadRu" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"> </a></li><li className="mb-4 last:mb-0">
                      <a href="https://t.me/TrustPad_CN" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"></a></li><li className="mb-4 last:mb-0"><a href="https://t.me/TrustPadTurkiye" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"> </a></li><li className="mb-4 last:mb-0"><a href="https://t.me/TrustPad_KOR" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"></a></li></ul></div><div className="text-sm"><p className="mb-4 text-lg text-secondary">Token</p><ul><li className="mb-4 last:mb-0"><a href="https://www.coingecko.com/en/coins/trustpad" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"></a></li><li className="mb-4 last:mb-0"><a href="https://coinmarketcap.com/currencies/trustpad/" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"></a></li><li className="mb-4 last:mb-0">
                        <a href="https://cryptorank.io/fundraising-platforms/trust-pad" target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"></a></li><li className="mb-4 last:mb-0"><a href target="_blank" rel="noopener noreferrer nofollow" className="no-underline text-white hover:text-main active:text-mainDark focus:text-main"><img src={pancakeswap} className="h-6 inline-block mr-2" />PancakeSwap{/* */}</a></li></ul></div><div className="text-sm"><p className="mb-4 text-lg text-secondary"></p><div className="gap-6 flex flex-col"><a href="https://www.certik.org/projects/trustpad" target="_blank" rel="noreferrer"></a> {/* */}<a href="/QuillAudits-TrustPad-Smart-Contract-Audit-Report.pdf" target="_blank"></a></div></div></div></div><div className="container pb-3 text-secondary"><div className="text-xs mt-3">Participants/Citizens from the Every countries are allowed to participate in the Pumps. USE METAMASK OR TRUST WALLET .</div><div className="flex justify-between items-center mt-6 gap-3 flex-col-reverse md:flex-row"><div className="text-xs">© Copyright PUMPTEAM {/* */}2022{/* */}. All rights reserved.{/* */}</div></div></div>
    </footer>




  )
}

export default Footer
